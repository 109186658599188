import React from 'react'
import icon from './icon.jpg'
import { css } from 'emotion'

const className = css({
  height: '50px',
  width: '50px'
})

export default () => <img className={className} src={icon} />
